<template>
  <div>
    <!-- <pre>{{ clientsSearch }}</pre> -->
    <h3
      class="grey--text"
    >
      Cliente
    </h3>
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.ci"
          disabled
          label="Documento de identidad"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          :value="clientsSearch.name + ' ' + clientsSearch.lastname"
          disabled
          label="Nombre"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.email"
          disabled
          label="Email"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.phone"
          disabled
          label="Teléfono"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.direction"
          disabled
          label="Dirección"
          filled
        />
      </v-col>
    </v-row>
    <!-- <pre>{{ warhouseMovementsDetail }}</pre> -->
    <h3
      class="grey--text"
    >
      Productos
    </h3>
    <v-data-table
      :headers="headers"
      :items="warhouseMovementsDetail"
      :loading="fetchingWarehouseMovementsDetail"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    >
      <template v-slot:[`item.cant`]="{ item }">
        {{ Math.abs(item.cant) }}
      </template>
      <template v-slot:[`item.packingCant`]="{ item }">
        {{ Math.abs(item.packingCant) }}
      </template>
      <template v-slot:[`item.amountWithoutTax`]="{ item }">
        {{ formatPrice(item.amountWithoutTax) }}
      </template>
      <template v-slot:[`item.subtotal`]="{ item }">
        {{ formatPrice(Math.abs(item.cant) * item.amountWithoutTax) }}
      </template>
    </v-data-table>

    <hr>
    <br>

    <v-row
      justify="end"
    >
      <v-col cols="3">
        <h3
          class="grey--text"
        >
          Subtotal:
        </h3>
        <h3
          class="grey--text"
        >
          % de descuento:
        </h3>
        <h3
          class="grey--text"
        >
          Monto descuento:
        </h3>
        <br>
        <h1>
          Total:
        </h1>
      </v-col>
      <v-col cols="3">
        <h3
          class="grey--text"
        >
          <span class="green--text">{{ formatPrice(id.totalAmountCurrencyReference) }}</span>
        </h3>
        <h3
          class="grey--text"
        >
          <span class="green--text">{{ id.discount }}%</span>
        </h3>
        <h3
          class="grey--text"
        >
          <span class="green--text">{{ formatPrice(((id.totalAmountCurrencyReference * id.discount) / 100)) }}</span>
        </h3>
        <br>
        <h1>
          <span class="green--text">{{ formatPrice(id.totalAmountCurrencyReference - (id.totalAmountCurrencyReference * id.discount) / 100) }}</span>
        </h1>
      </v-col>
    </v-row>

    <!-- <h3
      class="text-end grey--text"
    >
      Subtotal: <span class="green--text">{{ formatPrice(id.totalAmountCurrencyReference) }}</span>
    </h3>
    <h3
      class="text-end grey--text"
    >
      % de descuento: <span class="green--text">{{ id.discount }}%</span>
    </h3>
    <h3
      class="text-end grey--text"
    >
      Monto descuento: <span class="green--text">{{ formatPrice(((id.totalAmountCurrencyReference * id.discount) / 100)) }}</span>
    </h3>
    <h1
      class="text-end"
    >
      Total: <span class="green--text">{{ formatPrice(id.totalAmountCurrencyReference - (id.totalAmountCurrencyReference * id.discount) / 100) }}</span>
    </h1> -->
    <br>
    <h3
      class="grey--text"
    >
      Pagos
    </h3>
    <v-data-table
      :headers="headersPayments"
      :items="salePayments"
      :loading="fetchingBusinessSales"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    >
      <template v-slot:[`item.paymentMethods`]="{ item }">
        {{ item.paymentMethods.toUpperCase() }}
      </template>
      <!-- amountCurrencyReference -->
      <template v-slot:[`item.amountCurrencyReference`]="{ item }">
        {{
          formatPrice(item.amountCurrencyReference,
                      item.currencyId === 1 ? 'USD'
                      : item.currencyId === 2 ? 'EUR' : 'VES'
          )
        }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{
          formatPrice(item.amount,
                      item.currencyId === 1 ? 'USD'
                      : item.currencyId === 2 ? 'EUR' : 'VES'
          )
        }}
      </template>
      <template v-slot:[`item.totalAmountCurrencyReference`]="{ item }">
        {{ formatPrice(item.totalAmountCurrencyReference) }}
      </template>
    </v-data-table>
    <h3
      v-if="saleSurplus.length > 0"
      class="grey--text"
    >
      Cambios
    </h3>
    <v-data-table
      v-if="saleSurplus.length > 0"
      :headers="headersSurplus"
      :items="saleSurplus"
      :loading="fetchingBusinessSales"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    >
      <template v-slot:[`item.paymentMethods`]="{ item }">
        {{ item.paymentMethods.toUpperCase() }}
      </template>
      <template v-slot:[`item.amountCurrencyReference`]="{ item }">
        {{
          formatPrice(item.amountCurrencyReference,
                      item.currencyId === 1 ? 'USD'
                      : item.currencyId === 2 ? 'EUR' : 'VES'
          )
        }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{
          formatPrice(item.amount,
                      item.currencyId === 1 ? 'USD'
                      : item.currencyId === 2 ? 'EUR' : 'VES'
          )
        }}
      </template>
      <template v-slot:[`item.totalAmountCurrencyReference`]="{ item }">
        {{ formatPrice(item.totalAmountCurrencyReference) }}
      </template>
    </v-data-table>
    <p> <b>Vendedor: </b> {{ id.userName || '' }} {{ id.userLastName || '' }}</p>

    <!-- {{ id }} -->
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'SalesDetailTable',

    props: {
      id: {
        type: Object,
        default () {
          return {}
        },
      },
      dialog: {
        type: Boolean,
        default () {
          return false
        },
      },
    },

    data () {
      return {
        headers: [
          {
            text: 'Código',
            value: 'codigo',
          },
          {
            text: 'Descripcion',
            value: 'description',
          },
          {
            text: 'Cantidad de empaque',
            value: 'packingCant',
          },
          {
            text: 'Unidad de empaque',
            value: 'packingUnit',
          },
          {
            text: 'Cantidad para la unidad',
            value: 'cant',
          },
          {
            text: 'Unidad',
            value: 'salesUnit',
          },
          {
            text: 'Precio',
            value: 'amountWithoutTax',
          },
          {
            text: 'Subtotal',
            value: 'subtotal',
          },
        ],

        headersPayments: [
          {
            text: 'Método de importe',
            value: 'paymentMethods',
          },
          {
            text: 'Moneda de importe',
            value: 'currency',
          },
          {
            text: 'Valor de la moneda de importe al momento de la operacion',
            value: 'amountCurrencyReference',
          },
          {
            text: 'Monto',
            value: 'amount',
          },
          {
            text: 'Monto en $',
            value: 'totalAmountCurrencyReference',
          },
        ],

        headersSurplus: [
          {
            text: 'Método de retorno',
            value: 'paymentMethods',
          },
          {
            text: 'Moneda de retorno',
            value: 'currency',
          },
          {
            text: 'Valor de la moneda de retorno al momento de la operacion',
            value: 'amountCurrencyReference',
          },
          {
            text: 'Monto',
            value: 'amount',
          },
          {
            text: 'Monto en $',
            value: 'totalAmountCurrencyReference',
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fetchingWarehouseMovementsDetail',
        'warhouseMovementsDetail',
        'fetchingBusinessSales',
        'salePayments',
        'saleSurplus',
        'clientsSearch',
        'fecthingClients',
      ]),
    },

    watch: {
      async dialog (v) {
        // // // // // // // // // // // // // // // // // // // // // console.log(v)
        if (v) {
          await this.fetchClientById(this.id.clientId)
          await this.fetchWarehouseMovementsDetail({ id: this.id.id })
          await this.fetchBusinessSalesPayments({ id: this.id.id })
          await this.fetchBusinessSalesSurplus({ id: this.id.id })
        }
      },
    },

    async mounted () {
      // // // // // // // // // // // // // // // // // // // // // console.log('id', this.id)
      await this.fetchClientById(this.id.clientId)
      await this.fetchWarehouseMovementsDetail({ id: this.id.id })
      await this.fetchBusinessSalesPayments({ id: this.id.id })
      await this.fetchBusinessSalesSurplus({ id: this.id.id })
    },

    methods: {
      ...mapActions([
        'fetchWarehouseMovementsDetail',
        'fetchBusinessSalesPayments',
        'fetchBusinessSalesSurplus',
        'fetchClientById',
      ]),

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

  }
</script>
