var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-currency-usd","title":"Presupuestos","color":"orange"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.fetchingBusinessSales,"items":_vm.sales,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('sales-table-top'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"label":"Ventas","items":_vm.status,"no-data-text":"No hay coincidencia"},model:{value:(_vm.approved),callback:function ($$v) {_vm.approved=$$v},expression:"approved"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.start),"label":"Fecha de inicio","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalS),callback:function ($$v) {_vm.modalS=$$v},expression:"modalS"}},[_c('v-date-picker',{attrs:{"color":"light-blue darken-1","scrollable":"","locale":"es-ve"},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalS = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalS = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.end),"label":"Fecha final","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalE),callback:function ($$v) {_vm.modalE=$$v},expression:"modalE"}},[_c('v-date-picker',{attrs:{"scrollable":"","color":"light-blue darken-1","locale":"es-ve"},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalE = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalE = false}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"warning","elevation":"0"},on:{"click":_vm.genPdf}},[_vm._v(" Generar reporte   "),_c('v-icon',{attrs:{"right":"","dark":"","size":"25"}},[_vm._v(" mdi-printer ")])],1)],1),_c('br'),_c('br')]},proxy:true},{key:"item.description",fn:function(ref){
          var input = ref.item;
return [(input.description)?_c('span',[_vm._v(" "+_vm._s(input.description)+" ")]):_c('span',[_vm._v(" ... ")])]}},{key:"item.createdAt",fn:function(ref){
          var input = ref.item;
return [_vm._v(" "+_vm._s(new Date(input.createdAt).getDate())+"/"+_vm._s(new Date(input.createdAt).getMonth() + 1)+"/"+_vm._s(new Date(input.createdAt).getFullYear())+" ")]}},{key:"item.approved",fn:function(ref){
          var item = ref.item;
return [(item.approved)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-all ")]):_c('v-icon',{attrs:{"color":"gray"}},[_vm._v(" mdi-check ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('sales-actions',{attrs:{"item":item}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }