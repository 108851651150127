<template>
  <div>
    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="light-blue darken-1"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openedEditDialog = true"
        >
          <v-icon size="25">
            mdi-magnify-plus-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para ver el detalle del movimiento</span>
    </v-tooltip>

    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="primary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="downloadPdf"
        >
          <v-icon size="25">
            mdi-download
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para descargar el presupuesto</span>
    </v-tooltip>

    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="secondary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="printPdf"
        >
          <v-icon size="25">
            mdi-printer
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para imprimir el presupuesto</span>
    </v-tooltip>

    <!-- <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn> -->
    <sales-detail-dialog
      :id="item"
      v-model="openedEditDialog"
    />
  </div>
</template>

<script>
  import SalesDetailDialog from './SalesDetailDialog.vue'
  import { mapState, mapActions } from 'vuex'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'

  export default {
    name: 'SalesActions',
    components: {
      SalesDetailDialog,
    },
    props: {
      item: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingProducts',
        'user',
        'actionsParentBusiness',
        'fetchingWarehouseInputsDetail',
        'warhouseInputsDetail',
        'clientsSearch',
        'fecthingClients',
        'salePayments',
        'saleSurplus',
      ]),
    },
    methods: {
      ...mapActions([
        'fetchProducts',
        'deleteProducts',
        'activeProducts',
        'fetchWarhouseInputsDetail',
        'fetchClientById',
        'fetchBusinessSalesPayments',
        'fetchBusinessSalesSurplus',
      ]),

      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },

      async genPdf (print = false) {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        // const img = await this.getImageByUrl('/logo.png')

        // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
        // doc.setTextColor(255, 0, 0)
        // doc.text(`CÓDIGO: ${this.item.code}`, 130, 20)
        // doc.setTextColor(0, 0, 0)
        // doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(new Date())}`, 130, 27)
        // doc.setFontSize(13)
        // doc.setTextColor(0, 0, 0).text('Documento de identidad:', 10, 35)
        // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci, 63, 35)
        // doc.setTextColor(0, 0, 0).text('Nombres: ', 10, 42)
        // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.name, 32, 42)

        // if (this.clientsSearch?.lastname) {
        //   doc.setTextColor(0, 0, 0).text('Apellidos:', 10, 49)
        //   doc.setTextColor(100, 100, 100).text(this.clientsSearch?.lastname, 32, 49)
        // }

        // doc.setTextColor(0, 0, 0).text('Email:', 10, 60)
        // if (this.clientsSearch?.email) {
        //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.email, 25, 60)
        // }

        // doc.setTextColor(0, 0, 0).text('Teléfono:', 10, 67)
        // if (this.clientsSearch?.phone) {
        //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.phone, 32, 67)
        // }

        // doc.setTextColor(0, 0, 0).text('Dirección:', 10, 74)
        // if (this.clientsSearch?.direction) {
        //   doc.setTextColor(100, 100, 100).text(this.clientsSearch.direction, 33, 74)
        // }

        // // // // // // // // // // // // // // // console.log('ITEM', this.item)

        // // // // // // // // // // // // // console.log('salePayment', this.salePayments)
        // // // // // // // // // // // // // console.log('saleSurplus', this.saleSurplus)

        // doc.setFontSize(30).text('Tejidos Evertex C.A', 10, 20)
        // doc.setFontSize(17).text('Rif J- 30652826-1', 12, 30)
        // doc.setFontSize(12).setTextColor(100, 100, 100).text('Direccion:Zona Industrial Santa Cruz\nMaturín Centro Industrial Ferro A-1', 12, 37)

        doc.setFontSize(35).text('Sistema Genérico', 10, 23)

        doc.setTextColor(255, 0, 0)
        doc.setFontSize(18).text(`Presupuesto: ${this.item.code}`, 120, 20)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(new Date(this.item.createdAt))}`, 120, 27)
        doc.setFontSize(15).text('Vendedor:', 120, 35)

        doc.setFontSize(14).setTextColor(100, 100, 100).text(this.item.userName + ' ' + this.item.userLastName, 146, 35)

        doc.setFontSize(18).setTextColor(0, 0, 0).text('Datos Cliente', 10, 45)

        doc.setFontSize(13)

        doc.setTextColor(0, 0, 0).text('Nombres: ', 10, 55)
        doc.setTextColor(100, 100, 100).text(this.clientsSearch?.name + ' ' + this.clientsSearch?.lastname || '', 32, 55)

        doc.setTextColor(0, 0, 0).text('C.I / RIF: ', 130, 55)
        doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci, 150, 55)

        doc.setTextColor(0, 0, 0).text('Dirección: ', 10, 63)
        doc.setTextColor(100, 100, 100).text(this.clientsSearch.direction ? this.clientsSearch.direction.slice(0, 105) : '', 32, 63, {
          maxWidth: 170,
        })

        doc.setTextColor(0, 0, 0).text('Teléfono: ', 10, 75)
        doc.setTextColor(100, 100, 100).text(this.clientsSearch?.phone || '', 30, 75)

        doc.setTextColor(0, 0, 0).text('Email: ', 80, 75)
        doc.setTextColor(100, 100, 100).text(this.clientsSearch?.email || '', 94, 75)

        doc.setTextColor(0, 0, 0)

        doc.setFontSize(16)
        doc.text('Productos', 90, 90)

        // amountWithoutTax
        doc.autoTable({
          columns: [
            { title: 'Código', dataKey: 'codigo' },
            { title: 'Descripción', dataKey: 'description' },
            { title: 'Cant. empaque', dataKey: 'packingCant' },
            { title: 'U. empaque', dataKey: 'packingUnit' },
            { title: 'Cant. unidad', dataKey: 'cant' },
            { title: 'U. unidad', dataKey: 'salesUnit' },
            { title: 'Precio', dataKey: 'amountWithoutTax' },
            { title: 'Subtotal', dataKey: 'subtotal' },
          ],
          body: this.warhouseInputsDetail.map(item => {
            // // // // // // // // // // // // // // // console.log('product', item)
            return {
              ...item,
              cant: Math.abs(item.cant),
              packingCant: Math.abs(item.packingCant),
              subtotal: this.formatPrice(Math.abs(item.cant * item.amountWithoutTax)),
              amountWithoutTax: this.formatPrice(item.amountWithoutTax),
            }
          }),
          margin: {
            left: 10,
            top: 95,
          },
        })

        doc.setLineWidth(0.5).line(0, 165, doc.internal.pageSize.width, 165)
        doc.setLineWidth(0.5).line(0, 80, doc.internal.pageSize.width, 80)
        doc.text('Métodos de pago', 83, 175)

        doc.autoTable({
          columns: [
            { title: 'Método de importe', dataKey: 'paymentMethods' },
            { title: 'Moneda de importe', dataKey: 'currency' },
            { title: 'Valor de la moneda de importe', dataKey: 'amountCurrencyReference' },
            { title: 'Monto', dataKey: 'amount' },
            { title: 'Monto en $', dataKey: 'totalAmountCurrencyReference' },
          ],
          body: this.salePayments.map(item => {
            // // // // // // // // // // // // // // // console.log('product', item)
            return {
              ...item,
              paymentMethods: item.paymentMethods.toUpperCase(),
              amountCurrencyReference: this.formatPrice(
                item.amountCurrencyReference,
                item.currencyId === 1 ? 'USD'
                : item.currencyId === 2 ? 'EUR' : 'VES',
              ),
              amount: this.formatPrice(
                item.amount,
                item.currencyId === 1 ? 'USD'
                : item.currencyId === 2 ? 'EUR' : 'VES',
              ),
              totalAmountCurrencyReference: this.formatPrice(item.totalAmountCurrencyReference),
            }
          }),
          margin: {
            left: 10,
            top: 1100,
            right: 10,
          },
          startY: 183,
        })

        if (this.saleSurplus.length > 0) {
          doc.autoTable({
            columns: [
              { title: 'Método de retorno', dataKey: 'paymentMethods' },
              { title: 'Moneda de retorno', dataKey: 'currency' },
              { title: 'Valor de la moneda de retorno', dataKey: 'amountCurrencyReference' },
              { title: 'Monto', dataKey: 'amount' },
              { title: 'Monto en $', dataKey: 'totalAmountCurrencyReference' },
            ],
            body: this.saleSurplus.map(item => {
              // // // // // // // // // // // // // // // console.log('product', item)
              return {
                ...item,
                paymentMethods: item.paymentMethods.toUpperCase(),
                amountCurrencyReference: this.formatPrice(
                  item.amountCurrencyReference,
                  item.currencyId === 1 ? 'USD'
                  : item.currencyId === 2 ? 'EUR' : 'VES',
                ),
                amount: this.formatPrice(
                  item.amount,
                  item.currencyId === 1 ? 'USD'
                  : item.currencyId === 2 ? 'EUR' : 'VES',
                ),
                totalAmountCurrencyReference: this.formatPrice(item.totalAmountCurrencyReference),

              }
            }),
            margin: {
              left: 10,
              top: 1100,
              right: 10,

            },
            // startY: 210,
          })
        }

        doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 40, doc.internal.pageSize.width, doc.internal.pageSize.height - 40)
        doc.setFontSize(10)
        doc.text('Observaciones:', 10, doc.internal.pageSize.height - 35)
        doc.setFontSize(8)
        doc.text(this.item.description, 10, doc.internal.pageSize.height - 30)
        doc.setFontSize(12).setTextColor(100, 100, 100).text(`Subtotal:                ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 31)
        doc.setFontSize(12).setTextColor(100, 100, 100).text(`% de descuento:   ${this.item.discount}%`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 25)
        doc.setFontSize(12).setTextColor(100, 100, 100).text(`Monto descuento: ${this.formatPrice(((this.item.totalAmountCurrencyReference * this.item.discount) / 100))}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 19)
        doc.setFontSize(15)
        doc.setTextColor(0, 0, 0)
        doc.text(`Total:               ${this.formatPrice(this.item.totalAmountCurrencyReference - ((this.item.totalAmountCurrencyReference * this.item.discount) / 100))}`, doc.internal.pageSize.width - 63, doc.internal.pageSize.height - 10)

        doc.setLineWidth(0.5).line(75, doc.internal.pageSize.height - 17, 135, doc.internal.pageSize.height - 17)
        doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma y Sello', 96, doc.internal.pageSize.height - 10)

        if (print) {
          doc.autoPrint()
          doc.output('dataurlnewwindow')
        } else {
          doc.save('presupuesto.pdf')
        }
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      async downloadPdf () {
        await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.fetchClientById(this.item.clientId)
        await this.fetchBusinessSalesPayments({ id: this.item.id })
        await this.fetchBusinessSalesSurplus({ id: this.item.id })
        await this.genPdf()
      },

      async printPdf () {
        await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.fetchClientById(this.item.clientId)
        await this.fetchBusinessSalesPayments({ id: this.item.id })
        await this.fetchBusinessSalesSurplus({ id: this.item.id })
        await this.genPdf(true)
      },
      // async onDeactiveWarehouse () {
      //   await this.deleteProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
      // async onActiveWarehouse () {
      //   await this.activeProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
