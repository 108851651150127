<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-currency-usd"
          title="Presupuestos"
          class="px-5 py-3"
          color="orange"
        >
          <!-- <pre>{{ warhouseMovements }}</pre> -->
          <v-data-table
            :headers="headers"
            :loading="fetchingBusinessSales"
            :items="sales"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <sales-table-top />
              <v-row
                justify="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-autocomplete
                    v-model="approved"
                    label="Ventas"
                    :items="status"
                    no-data-text="No hay coincidencia"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="modalS"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate(start)"
                        label="Fecha de inicio"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="start"
                      color="light-blue darken-1"
                      scrollable
                      locale="es-ve"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="modalS = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="modalS = false"
                      >
                        <!-- @click="$refs.dialog.save(start)" -->
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="modalE"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate(end)"
                        label="Fecha final"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="end"
                      scrollable
                      color="light-blue darken-1"
                      locale="es-ve"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="modalE = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="modalE = false"
                      >
                        <!-- @click="$refs.dialog.save(end)" -->
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- <v-col cols="2" /> -->
              </v-row>

              <v-row justify="center">
                <v-btn
                  color="warning"
                  elevation="0"
                  class="ma-2 white--text"
                  @click="genPdf"
                >
                  Generar reporte &nbsp;
                  <v-icon
                    right
                    dark
                    size="25"
                  >
                    mdi-printer
                  </v-icon>
                </v-btn>
              </v-row>

              <br>
              <br>
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>

            <template v-slot:[`item.approved`]="{ item: item }">
              <v-icon
                v-if="item.approved"
                color="green"
              >
                mdi-check-all
              </v-icon>

              <v-icon
                v-else
                color="gray"
              >
                mdi-check
              </v-icon>
              <!-- <h1>hioh</h1> -->
            </template>

            <template v-slot:[`item.actions`]="{ item: item }">
              <sales-actions
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import SalesTableTop from './SalesTableTop'
  import SalesActions from './SalesActions.vue'
  import { DateTime } from 'luxon'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'

  // import ConcessionRequestActions from './ConcessionRequestActions.vue'

  export default {
    name: 'ConcessionRequestTable',
    components: {
      SalesTableTop,
      SalesActions,
      // ConcessionRequestActions,
    },
    data () {
      return {
        start: '',
        end: '',
        relatedWarehouseId: '',
        approved: 'TODAS',
        modalS: false,
        modalE: false,
        openedAddDialog: false,
        status: ['TODAS', 'APROBADAS', 'SIN APROBAR'],
        initialData: true,
        type: 0,
        typeFilter: [
          {
            name: 'TODAS',
            value: 0,
          },
          {
            name: 'Presupuestos realizados',
            value: 1,
          },
          {
            name: 'Despachos',
            value: 2,
          },
        ],
        headers: [
          {
            text: 'Fecha del movimiento',
            value: 'createdAt',
          },
          {
            text: 'Codigo',
            value: 'code',
          },
          {
            text: 'Observaciones',
            value: 'description',
          },
          {
            text: 'Estatus del presupuesto',
            value: 'approved',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'fetchingBusinessSales',
        'businessSales',
      ]),

      sales () {
        // // // // // // // // console.log(this.businessSales)
        // // // // // // // // console.log(this.approved)
        // return this.businessSales
        let res = []
        // // // // // // // // // // // // // // // // // console.log(this.warehousesConcessions)
        if (this.approved === 'TODAS') {
          // // // // // // // // console.log('total')
          res = this.businessSales
        } else if (this.approved === 'APROBADAS') {
          // // // // // // // // console.log('con')
          res = this.businessSales.filter(item => item.approved === 1)
        } else if (this.approved === 'SIN APROBAR') {
          // // // // // // // // console.log('sin')
          res = this.businessSales.filter(item => item.approved === 0)
        }

        // // // // // // // // console.log('Funciona?', this.dateCheck(this.start, this.end, '2022-03-15T12:16:13.552Z'))

        return res.filter(item => this.dateCheck(this.start, this.end, item.createdAt))
      },
    },

    created () {
      this.end = DateTime.fromJSDate(this.nextWeek()).toFormat('yyyy-MM-dd')
      this.start = DateTime.fromJSDate(this.lastWeek()).toFormat('yyyy-MM-dd')
      this.initialData = false
    },

    methods: {
      async genPdf () {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        doc.setFontSize(38).text('Sistema Genérico', 10, 23)
        // doc.setTextColor(255, 0, 0)
        // doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(new Date())}`, 130, 27)

        // doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
        // doc.setTextColor(0, 0, 0)
        // doc.setFontSize(14).text(`Número de guía: ${this.item.guideNumber}`, 130, 35)

        // doc.setTextColor(0, 0, 0).text('Proveedor: ', 10, 45)
        // doc.setTextColor(100, 100, 100).text(this.item.providerName || '', 35, 45)

        // // doc.setTextColor(0, 0, 0).text('C.I / RIF: ', 130, 65)
        // // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci || '', 150, 65)
        // // // // // // // // // // // // console.log('list', this.item.providerAddress, this.item.providerAddress.length)

        // doc.setTextColor(0, 0, 0).text('Dirección: ', 10, 53)
        // doc.setTextColor(100, 100, 100).text(this.item.providerAddress ? this.item.providerAddress.slice(0, 105) : '', 33, 53, {
        //   maxWidth: 170,
        // })

        // doc.setTextColor(0, 0, 0).text('Teléfono: ', 10, 65)
        // doc.setTextColor(100, 100, 100).text(this.item.providerPhone || '', 32, 65)

        // doc.setTextColor(0, 0, 0).text('Email: ', 80, 65)
        // doc.setTextColor(100, 100, 100).text(this.item.providerEmail || '', 95, 65)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18).setTextColor(0, 0, 0).text(`Reporte de ventas desde ${this.formatDate(this.start)} hasta ${this.formatDate(this.end)}`, 30, 50)

        doc.autoTable({
          columns: [
            { title: 'Fecha', dataKey: 'createdAt' },
            { title: 'Código', dataKey: 'code' },
            { title: 'Observaciones', dataKey: 'description' },
            { title: 'Estatus', dataKey: 'status' },
            { title: 'Total', dataKey: 'total' },
          ],
          // body: this.warhouseInputsDetail.map(item => {
          //   return {
          //     ...item,
          //     cant: Math.abs(item.cant),
          //   }
          // }),
          body: this.sales.map(item => {
            return {
              ...item,
              createdAt: this.alterformatDate(item.createdAt),
              status: item.approved ? 'APROBADO' : 'SIN APROBAR',
              total: this.formatPrice(item.totalAmountCurrencyReference),
            }
          }),
          margin: {
            left: 10,
            top: 60,
          },
        })
        // doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 30, doc.internal.pageSize.width, doc.internal.pageSize.height - 30)
        // doc.setFontSize(10)
        // doc.text('Observaciones:', 10, doc.internal.pageSize.height - 25)
        // doc.setFontSize(8)
        // doc.text(this.item.description, 10, doc.internal.pageSize.height - 20)
        // doc.setFontSize(15)
        // doc.text(`Total: ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 10)

        // doc.autoPrint(),
        // doc.output('orden')
        // doc.autoPrint()
        // This is a key for printing
        // doc.output('dataurlnewwindow')
        doc.autoPrint()
        doc.output('dataurlnewwindow')
      },

      dateCheck (from, to, check) {
        const fDate = Date.parse(from)
        const lDate = Date.parse(to)
        const cDate = Date.parse(check)

        if ((cDate <= lDate && cDate >= fDate)) {
          return true
        } else {
          return false
        }
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      alterformatDate (dateProp) {
        const date = new Date(dateProp)

        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      formatDate (dateProp) {
        return DateTime
          .fromFormat(dateProp, 'yyyy-MM-dd')
          .toFormat('dd/MM/yyyy')
      },

      lastWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
      },

      nextWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      },
    },
  }
</script>
