var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"grey--text"},[_vm._v(" Cliente ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.ci,"disabled":"","label":"Documento de identidad","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.name + ' ' + _vm.clientsSearch.lastname,"disabled":"","label":"Nombre","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.email,"disabled":"","label":"Email","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.phone,"disabled":"","label":"Teléfono","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.direction,"disabled":"","label":"Dirección","filled":""}})],1)],1),_c('h3',{staticClass:"grey--text"},[_vm._v(" Productos ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.warhouseMovementsDetail,"loading":_vm.fetchingWarehouseMovementsDetail,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }},scopedSlots:_vm._u([{key:"item.cant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.cant))+" ")]}},{key:"item.packingCant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.packingCant))+" ")]}},{key:"item.amountWithoutTax",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.amountWithoutTax))+" ")]}},{key:"item.subtotal",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(Math.abs(item.cant) * item.amountWithoutTax))+" ")]}}],null,true)}),_c('hr'),_c('br'),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('h3',{staticClass:"grey--text"},[_vm._v(" Subtotal: ")]),_c('h3',{staticClass:"grey--text"},[_vm._v(" % de descuento: ")]),_c('h3',{staticClass:"grey--text"},[_vm._v(" Monto descuento: ")]),_c('br'),_c('h1',[_vm._v(" Total: ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('h3',{staticClass:"grey--text"},[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.formatPrice(_vm.id.totalAmountCurrencyReference)))])]),_c('h3',{staticClass:"grey--text"},[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.id.discount)+"%")])]),_c('h3',{staticClass:"grey--text"},[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.formatPrice(((_vm.id.totalAmountCurrencyReference * _vm.id.discount) / 100))))])]),_c('br'),_c('h1',[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.formatPrice(_vm.id.totalAmountCurrencyReference - (_vm.id.totalAmountCurrencyReference * _vm.id.discount) / 100)))])])])],1),_c('br'),_c('h3',{staticClass:"grey--text"},[_vm._v(" Pagos ")]),_c('v-data-table',{attrs:{"headers":_vm.headersPayments,"items":_vm.salePayments,"loading":_vm.fetchingBusinessSales,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }},scopedSlots:_vm._u([{key:"item.paymentMethods",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentMethods.toUpperCase())+" ")]}},{key:"item.amountCurrencyReference",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.amountCurrencyReference, item.currencyId === 1 ? 'USD' : item.currencyId === 2 ? 'EUR' : 'VES' ))+" ")]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.amount, item.currencyId === 1 ? 'USD' : item.currencyId === 2 ? 'EUR' : 'VES' ))+" ")]}},{key:"item.totalAmountCurrencyReference",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.totalAmountCurrencyReference))+" ")]}}],null,true)}),(_vm.saleSurplus.length > 0)?_c('h3',{staticClass:"grey--text"},[_vm._v(" Cambios ")]):_vm._e(),(_vm.saleSurplus.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headersSurplus,"items":_vm.saleSurplus,"loading":_vm.fetchingBusinessSales,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }},scopedSlots:_vm._u([{key:"item.paymentMethods",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentMethods.toUpperCase())+" ")]}},{key:"item.amountCurrencyReference",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.amountCurrencyReference, item.currencyId === 1 ? 'USD' : item.currencyId === 2 ? 'EUR' : 'VES' ))+" ")]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.amount, item.currencyId === 1 ? 'USD' : item.currencyId === 2 ? 'EUR' : 'VES' ))+" ")]}},{key:"item.totalAmountCurrencyReference",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.totalAmountCurrencyReference))+" ")]}}],null,true)}):_vm._e(),_c('p',[_c('b',[_vm._v("Vendedor: ")]),_vm._v(" "+_vm._s(_vm.id.userName || '')+" "+_vm._s(_vm.id.userLastName || ''))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }